'use client';

import { useEffect } from 'react';
import { usePathname } from 'next/navigation';

// ----------------------------------------------------------------------

let contactInfoSent = false;

export function fireContactInfo(data) {
  if (Object.keys(data).length === 0 || contactInfoSent) {
    return;
  }
  const name = String(data.name ?? '');
  const firstName = name.substring(0, name.indexOf(' '));
  const lastName = name.substring(name.indexOf(' ') + 1);

  console.log('contactInfo', { firstName, lastName, email: data.email, phone: data.phone });
  contactInfoSent = true;
}

export function fireStart() {
  console.log('fireStart');
}

export function fireRegistered() {
  console.log('fireRegistered');
}

export function fireCreatedFirstCompany() {
  console.log('fireCreatedFirstCompany');
}

export function fireCreatedFirstContainer() {
  console.log('fireCreatedFirstContainer');
}

export function fireFirstContainerInstalled() {
  console.log('fireFirstContainerInstalled');
}


// ----------------------------------------------------------------------

export function firePageView() {
  console.log('track', 'PageView');
}

export function firePurchase(conversionValue = 1, isFinal = false) {
  console.log('track', 'Purchase', { value: conversionValue, currency: 'EUR', isPartial: !isFinal });
}

const Tracking = () => {
  const pathname = usePathname();

  useEffect(() => {
    setTimeout(() => {
      firePageView();
    });
  }, [pathname]);

  return null;
};

export default Tracking;
