'use client';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// routes
import { useSearchParams, useRouter } from 'src/routes/hooks';
// config
import { IS_CLIENT_SERVER, PATH_AFTER_LOGIN } from 'src/config-global';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import GoogleLogin from './google-login';
import { useSignupContext } from '../../../../components/signup-popup/context/signup-context';

// ----------------------------------------------------------------------

export default function LoginForm({ onModeChanged }) {
  const signup = useSignupContext();
  const { login } = useAuthContext();

  const router = useRouter();

  const [errorMsg, setErrorMsg] = useState('');

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const password = useBoolean();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await login?.(data.email, data.password);
      signup.onClose();

      router.push(returnTo || PATH_AFTER_LOGIN);


    } catch (error) {
      console.error(error);
      reset();
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 2 }}>
      <Stack spacing={0.5}>
        <Typography variant='h4'>Sign in to your account</Typography>
        <Stack direction='row' spacing={0.5}>
          <Typography variant='body2'>New user?</Typography>

          <Link onClick={() => onModeChanged('register')} variant='subtitle2' sx={{ cursor: 'pointer' }} color='accent.main'>
            Create an account
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );

  const smallFields = true;

  const textFieldInputSx = {
    background: (theme) => theme.palette.background.paper,
    height: 40,
  };

  const renderForm = (
    <Stack spacing={1.25}>
      {!!errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

      <RHFTextField name='email' label='Email address' size={smallFields ? 'small' : 'default'} InputProps={{ sx: textFieldInputSx }} />

      <RHFTextField
        name='password'
        label='Password'
        type={password.value ? 'text' : 'password'}
        size={smallFields ? 'small' : 'default'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={password.onToggle} edge='end'>
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
          sx: textFieldInputSx,
        }}
      />

      {false && <Link variant='body2' color='inherit' underline='always' sx={{ alignSelf: 'flex-end' }}>
        Forgot password?
      </Link>}

      <LoadingButton
        fullWidth
        color='inherit'
        type='submit'
        variant='contained'
        loading={isSubmitting}
        size={smallFields ? 'default' : 'large'}
      >
        Login
      </LoadingButton>
    </Stack>
  );

  return (
    <Stack spacing={1} sx={{ pb: 2 }}>
      {renderHead}
      <Stack>
        {!IS_CLIENT_SERVER && <>
          <GoogleLogin />
          <Divider sx={{ borderStyle: 'dashed', my: 1.5 }} />
        </>
        }
        <FormProvider methods={methods} onSubmit={onSubmit}>
          {renderForm}
        </FormProvider>
      </Stack>
    </Stack>
  );
}

LoginForm.propTypes = {
  onModeChanged: PropTypes.func,
};
